.preloader {
    background-color: #f5f6f7;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    min-height: 282px;

  }
  
