.popup {
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0.5s, opacity 0.5s linear;
    gap: 15px;
    max-width: 1440px;
}

.popup_opened {
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.popup__title {
    font-family: Roboto, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px;
    margin: 0;
    margin-bottom: 22px;
}

.popup__button-submit {
    margin-top: 8px;
    margin-bottom: 0;
    color: #fff;
    height: 50px;
    font-size: 18px;
    border: none;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    line-height: 24px;
}

.popup__input-text {
    font-family: Inter, sans-serif;
    height: 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: #000;
    border-width: 0 0 2px;
    border-color: rgba(0, 0, 0, .2);
    padding-bottom: 10px;
    outline: 0;
    width: 100%;
    margin-top: 9px;
    margin-bottom: 31px;

}

.popup__input-text:nth-child(1) {
    padding-top: 76px;
}

.popup__input-text:nth-child(3) {
    padding-top: 9px;
}

.popup__input-text_type_error {
    border-color: #FF0000;
}

.popup__input-text:focus {
    border-color: rgba(0, 0, 0);
}

.popup__input-error {
    padding-top: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #FF0000;
    display:block;
}

.popup__form-set {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border: none;
    min-inline-size: min-content;
}

.popup__container {
    display: grid;
    z-index: 1;
    width: 430px;
    border-radius: 10px;
    background-color: #fefefe;
    padding: 34px 36px 28px;
    margin: 0;
    box-sizing: border-box;
}

.popup__close {
    margin-left: 485px;
    border: none;
    background: none;
}

.popup__close::before {
    content: url("../../images/Close-Icon.svg");
}

.popup__footer {
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    margin: 16px 0 0;
}

.popup__switch-button {
    border: none;
    background-color: Transparent;
    color: #2F71E5;
    cursor: pointer;
}

@media screen and (max-width: 640px) {
    .popup {
        gap: 13px;
        justify-content: flex-start;
        padding-top: 85px;
    }

    .popup__title {
        font-size: 18px;
        line-height: 22px;
    }

    .popup__submit-btn {
        margin-top: 46px;
    }

    .popup__input-text {
        padding-bottom: 9px;
    }

    .popup__container {
        padding: 25px 22px;
    }

    .popup__close {
        margin-left: 250px;
    }
}

@media screen and (max-width: 640px) {
    .popup__container {
        width: 100%;
        padding: 16px 16px 252px 16px;
    }
}