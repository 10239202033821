.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px  104px 28px;
}

.footer__nav {
    display: flex;
    align-items: center;
    gap: 40px;
}

.footer__social {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0;
    margin: 0; 
}

.footer__item {
    list-style: none;
}

.footer__link{
    text-decoration: none;
}

.footer__copyright {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #B6BCBF;
}

@media screen and (max-width: 800px) {
    .footer {
        margin: auto 40px;
        margin: 24px  40px 18px;
    }

}

@media screen and (max-width: 535px) {
    .footer {

        margin: 24px  16px 18px;
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 40px;
    }

    .footer__nav {
        align-items: stretch;
        justify-content: space-between;
    }

}