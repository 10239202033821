.mobile-nav__header{
    padding: 16px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    color: #1A1B22;;
    border-bottom: 1px solid #C4C4C4;
    position: sticky;
    top: 0;
    z-index: 5;
}
.mobile-nav__header_type_dark{
    background-color: #1A1B22;
    color: #fff;
}

.mobile-nav__logo {
    font-family: RobotoSlab, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    border-bottom: 1px solid #C4C4C403;
}

.mobile-nav {
    overflow: hidden;
    background-color: #1A1B22;
    color: #fff;
    position: relative;
    margin: auto;
    border-radius: 0 0 10px 10px;
    width: 100%;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: visibility 0.5s, opacity 0.5s linear;
    position: fixed;
}

.mobile-nav_type_dark{
    background-color: #fff;
    color: #1A1B22;
}

.mobile-nav_active {
    opacity: 1;
    visibility: visible;
    pointer-events: fill;
    transition: visibility 0.5s, opacity 0.5s linear;
    position: fixed;
}


.mobile-nav__icon {
    background: transparent;
    border: none;
    cursor: pointer; 
}

.mobile-nav_open::after {
    content: url('../../images/menu-w.svg');
}

.mobile-nav_open_type_dark::after {
    content: url('../../images/menu.svg');
}


.mobile-nav_close::after {
    content: url('../../images/close.svg');
}
.mobile-nav_close_type_dark::after {
    content: url('../../images/close-b.svg');
}

.mobile-nav__links {
    display: flex;
    flex-direction: column;
}

.mobile-nav__link {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 56px;
    display: block;
    text-decoration: none;
    padding: 16px;
    color: #000;
}

.mobile-nav__link_type_dark {
    color: #fff;
}

.mobile-nav__link:hover {
    background-color: #ddd;
    color: black;
}

.mobile-nav__link_active {
    background-color: #191919;
    color: white;
}

.mobile-nav_button {
    margin: 22px ;
    height: 56px;
    width: 288px;
    border-radius: 100px;
    align-self: center;

}