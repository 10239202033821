.card__item {
    position: relative;
    width: 100%;
    min-height: 576px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.card {
    color: #000;
    background-color: #FFF;
    width: 100%;
    border-radius: 15px;
    margin: auto;
    list-style: none;
}

.card__image {
    width: 100%;
    border-radius: 15px 15px 0 0;
    object-fit: cover;
    height: 272px;

}

.card__date {
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #B6BCBF;

}

.card__info {
    margin: 20px 24px 24px;
}

.card__title {
    font-family: RobotoSlab, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;
    margin: 12px 0 16px;
    color: #1A1B22;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; 
    line-clamp: 2;

}

.card__quote {
    margin: 0 0 8px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.card__link {
    text-decoration: none;
    text-transform: uppercase;
    font-family: RobotoSlab, sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.4000000059604645px;
    text-align: left;
    color: #B6BCBF;
}

.card__icon {
    position: absolute;
    top: 24px;
    right: 24px;
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: #fff;
    border: none;
    cursor: pointer;
}

.card__icon:disabled {
    cursor:not-allowed;
}
.card__save {
    position: absolute;
    top: 24px;
    right: 70px;
    height: 40px;
    width: fit-content;
    border-radius: 8px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 20px;
}

.card__tag {
    position: absolute;
    top: 24px;
    left: 24px;
    height: 40px;
    width: fit-content;
    border-radius: 8px;
    background-color: #fff;
    border: none;
    cursor: pointer;
    display: none;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    padding: 8px 20px;
}

.card__tag_active {
    display: block;
}

.card__bookmark::before {
    content: url('../../images/bookmark.svg');
}

.card__bookmark-bold::before {
    content: url('../../images/bookmark-bold.svg');
}

.card__bookmark-marked::before {
    content: url('../../images/bookmark-b.svg');
}

.card__trash::before {
    content: url('../../images/trash.svg');
}

.card__trash-bold::before {
    content: url('../../images/trash-bold.svg');
}


@media screen and (max-width: 1020px) {
    .card__date {
        font-size: 18px;
        line-height: 24px;
    }

    .card__info {
        margin: 16px;
    }

    .card__title {
        font-size: 22px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .card__quote {
        font-size: 16px;
        font-weight: 400;
    }

    .card__link {
        font-size: 16px;
        line-height: 20px;
    }

    .card__save {
        display: none;
    }
}