.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    text-align: center;
}

.not-found__img {
    height: 74px;
    width: 74px;
}

.not-found__title {
    font-family: RobotoSlab, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 30px;   
    color: #1A1B22;
    margin: 30px 0 16px;
}

.not-found__desc{
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #B6BCBF;
    margin: 0;
}