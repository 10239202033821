.navigation {
    display: flex;
    align-items: center;
    gap: 42px;
    font-family: Roboto, sans-serif;
    ;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.navigation__link {
    color: inherit;
    text-decoration: none;
    padding: 29px 0;
}

.navigation__link_active {
    border-bottom: 2px solid;
}

.navigation_logout, .navigation_logout-w {
    height: 48px;
    padding: 0 49px 0  19px;
}
.navigation_logout::after {
    content: url('../../images/logout.svg');
    position: absolute; 
    top: 50%; 
    margin-top: -12px;
    margin-left: 12px;
}

.navigation_logout-w::after {
    content: url('../../images/logout-w.svg');
    position: absolute; 
    top: 50%; 
    margin-top: -12px;
    margin-left: 12px;
}

.navigation_signin {
    height: 48px;
    width: 176px;  
}

@media screen and (max-width: 800px) {
    .navigation {
        font-size: 16px;
    }

    .navigation__link {
        padding: 22px 0;
    }
}

@media screen and (max-width: 535px) {
    .navigation {
        display: none;
    }
}