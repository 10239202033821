.card-list {
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 33% 33% 33%;;
    gap: 16px;
    padding: 80px 0;
}


@media screen and (max-width: 700px) {
    .card-list {
        grid-template-columns:  50% 50%;
    }
}


@media screen and (max-width: 510px) {
    .card-list {
        grid-template-columns: 1fr;
    }
}