.about{
    color: black;
    display: flex;
    gap: 56px;
    margin: 80px 104px;
}

.about__img {
    height: 464px;
    width: 464px;
    left: 0px;
    top: 0px;
    border-radius: 1000px;  
}

.about__title {
    margin-top: 51px;
    font-family: Roboto Slab, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
}

.about__desc{
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    max-width: 600px;
}

@media screen and (max-width: 800px) {
    .about{
        margin: 24px;
    }

    .about__img {
        height: 232px;
        width: 232px;
        flex-grow: 1;
    }
    .about__text{
        flex-grow: 2;
    }

}


@media screen and (max-width: 500px) {
    .about{
        flex-direction: column;
        align-items: center;
    }

    .about__img {
        width: 100%;
        height: auto;

    }

}