.results {
    display: flex;
    flex-direction: column;
}
.results__title{
    font-family: RobotoSlab, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
    color: #1A1B22;
    text-align: left;
    margin: 0;
}

.results__button {
    align-self: center;
    margin-top: 64px;
}

@media screen and (max-width: 510px) {
    .results__title{
        font-size: 30px;
        font-weight: 400;
        line-height: 34px; 
    }
}