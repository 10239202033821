.main {
    color: #fff;
    z-index: 0;
    margin-top: -80px;
}

.main__hero {
    display: flex;
    justify-content: center;
    background-image: url(../../images/hero.avif);
    background-repeat: no-repeat;
    background-size: cover;
}

.main__search {
    margin: 160px auto 80px;
    max-width: 608px;
}

.main-list {
    margin: auto 104px;
    padding: 80px 0;
}

.main__title {
    margin: auto;
    font-family: RobotoSlab, sans-serif;
    ;
    font-size: 60px;
    font-weight: 400;
    line-height: 64px;
}

.main__subtitle {
    margin: 32px auto 88px;
    font-family: Roboto, sans-serif;
    ;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
}

@media screen and (max-width: 800px) {
    .main__hero {
        background-image: url(../../images/hero-tablet.avif);
    }

    .main__search {
        margin: 104px auto 32px;
        max-width: 448px;
    }

    .main__title {
        font-size: 36px;
        line-height: 40px;
    }

    .main__subtitle {
        margin: 16px auto 48px;
        font-size: 18px;
        line-height: 24px;
    }

    .main-list {
        margin: auto 40px;
    }
}

@media screen and (max-width: 320px) {
    .main__hero {
        background-image: url(../../images/hero-mobile.avif);
    }

    .main__search {
        margin: 104px 16px 32px;
        max-width: 285px;
    }

    .main__list {
        margin: auto 16px;
    }
}